import { Component, Input, OnInit } from '@angular/core';

import { CommonService } from '../services/common.service';
import { ElementRef, ViewChild } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-other-document-upload',
  templateUrl: './other-document-upload.component.html',
  styleUrls: ['./other-document-upload.component.css']
})
export class OtherDocumentUploadComponent  {
  @Input() uploadOrderId;
  uploadStatus:boolean = false;
  @ViewChild('fileInput') fileInput: ElementRef;
  constructor( private httpService: CommonService,private http: HttpClient,public activeModal: NgbActiveModal,private toastService: ToastrService) { }


  clearFile(){
    this.activeModal.close();
  }
  upload() {
    this.uploadStatus=true
    const fileInput: HTMLInputElement = this.fileInput.nativeElement;

    // Check if a file is selected
    if (fileInput.files && fileInput.files.length > 0) {
        const file: File = fileInput.files[0];

        // Create FormData object and append data
        const formData: FormData = new FormData();
        formData.append('orderId', this.uploadOrderId);
        formData.append('othersDocumentFile', file);

        const url = `${environment.apiPath}/api/sc/saas/uploadOthersDoc`;

        const headers = new HttpHeaders();

        this.http.post(url, formData, { withCredentials: true }).subscribe(
            (response: any) => {
                // Success response handling
                this.toastService.success('File uploaded successfully');
                this.activeModal.close();
                this.uploadStatus=false

            },
            (error: any) => {
                this.uploadStatus=false

                // Error response handling
                if (error.status === 409) {
                    this.toastService.error('Document is already present');
                } else {
                    this.toastService.error('File upload failed. Please try again.');
                }
                console.error('Error uploading file:', error);
            }
        );
    } else {
        this.uploadStatus=false

        this.toastService.error('No file selected. Please choose a file to upload.');
    }
}


}
