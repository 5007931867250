import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { formatDate } from '@angular/common';
import { timeStamp } from 'console';

@Component({
  selector: 'app-view-receipt',
  templateUrl: './view-receipt.component.html',
  styleUrls: ['./view-receipt.component.css']
})
export class ViewReceiptComponent implements OnInit {
  orderData: any;
  invoiceTotal:any=''
  iscreditLimitApplied: boolean=false;
  constructor(public activeModal: NgbActiveModal) { }

  @Input() data;
  formattedDate: any;
  printInvoice: boolean;
  amountPaid:any=0.0;
  paymentMethod:any=[]
  ngOnInit(): void {
   
    if (this.data.length > 0 && this.data[0].paymentMethodList.includes('Credit Limit')) {
     this.iscreditLimitApplied=true
          } else {
            this.iscreditLimitApplied=false
          }
    this.data.map((element)=>{
      this.amountPaid=this.amountPaid+parseFloat(element.amountPaid);
      this.paymentMethod=this.paymentMethod.concat(element['paymentMethodList'])
    })
 
    this.orderData = JSON.parse(sessionStorage.getItem('ordersIdsInInvoiceOrderDetail'));
    this.invoiceTotal=this.orderData?.invoiceTotal
 
    this.paymentMethod=[... new Set(this.paymentMethod)].join('+');
    this.data.map((element, index) => {
      if (this.orderData.invoiceNumber === element.invoiceNumber) {
        this.formattedDate = formatDate(element.orderDate, 'MM/dd/yyyy', 'en-US');
      }
    })
    console.log(this.amountPaid)
    

  }

  hasPaymentMethod(methods: string[]): boolean {
    
    console.log(this.data,"hasPaymentMethod")
    return methods.some(method => this.data[0].paymentMethodList.includes(method));
  }


  printComponent() {
    setTimeout(() => {
      window.print();
    }, 1000);
  }

  closeModal() { this.activeModal.close(); }
}
